import React from "react";
import "./Highlight.scss";

const Highlight = ({ children, isSpecial = false, isDate = false }) => {
  return (
    <span
      className={
        "highlight" +
        (isSpecial ? " highlight--is-special" : "") +
        (isDate ? " highlight--is-date" : "")
      }
    >
      {children}
    </span>
  );
};

export default Highlight;
