import React, { useState, useEffect } from "react";
import round from "lodash/round";
import floor from "lodash/floor";
import Circle from "./Circle";
import differenceInDays from "date-fns/differenceInDays";
import format from "date-fns/format";
import subDays from "date-fns/subDays";
import TextItem from "./TextItem";
import Highlight from "./Highlight";
import {
  arrivalDay,
  nacimiento,
  featuredYears,
  circlesLabels,
  cicloDias,
  inDays,
} from "../config";
//import addDays from "date-fns/addDays";
import "./App.scss";

const App = () => {
  const [hoy, setHoy] = useState(new Date());
  const days = differenceInDays(hoy, arrivalDay);
  const years = round(days / inDays.averageYear, 2);
  const months = round(days / inDays.averageMonth, 1);
  const vecesEnCork = floor(days / inDays.cork2017, 1);
  const diasDeVida = differenceInDays(hoy, nacimiento);
  const comparacion = subDays(arrivalDay, days);
  const comparacionFormateada = format(comparacion, "PPPP");
  const porcentaje = floor((days * 100) / diasDeVida, 2);
  const vecesNeuquenMasNecochea = floor(days / inDays.neuquenAndNecochea, 2);
  const unoDeCadaXDias = Math.ceil(100 / porcentaje);
  // const fechaMitad = format(addDays(arrivalDay, floor(days / 2)), "PPPP");

  /*
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      const getPosition = throttle((e) => {
        setMousePosition({ x: e.clientX, y: e.clientY });
      }, 50);

      window.addEventListener("mousemove", getPosition);

      return () => window.removeEventListener("mousemove", getPosition);
    }
  }, []);
*/

  const getDecimals = (aNumber) => aNumber - +aNumber.toString().split(".")[0];

  useEffect(() => {
    const interval = setInterval(() => {
      const newDate = new Date();
      differenceInDays(newDate, arrivalDay) !== days && setHoy(newDate);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoy]);

  const mainItems = [
    {
      ...circlesLabels.days,
      number: days,
      percentage: featuredYears.includes(days)
        ? 0
        : days > hoy.getFullYear() || days <= cicloDias
        ? getDecimals(days / cicloDias) * 100
        : getDecimals((days - cicloDias) / (hoy.getFullYear() - cicloDias)) *
          100,
    },
    {
      ...circlesLabels.months,
      number: months,
      percentage:
        months > 100
          ? getDecimals(months / 100) * 100
          : getDecimals(months / 10) * 100,
    },
    {
      ...circlesLabels.years,
      number: years,
      percentage: getDecimals(years) * 100,
    },
    {
      ...circlesLabels.vecesEnCork,
      number: vecesEnCork,
      percentage: getDecimals(vecesEnCork / 100) * 100,
    },
    {
      ...circlesLabels.porcentaje,
      number: porcentaje,
      percentage: porcentaje % 1 > 0 ? porcentaje : 0,
    },
  ];
  return (
    <div className="app">
      <h1>
        El tiempo que llevo en <span style={{ fontWeight: 500 }}>Irlanda</span>
      </h1>
      <h3>
        Mediciones y comparaciones con respecto al tiempo que hace
        <br />
        que estoy viviendo en esta tierra
      </h3>

      <div className="content">
        {mainItems.map((circleProps, index) => {
          return <Circle {...circleProps} key={index} />;
        })}
      </div>
      <div className="comparacion">
        <TextItem>
          Si el tiempo transcurrido hasta ahora, se contara desde el día de la
          llegada hacia atrás, la fecha alcanzada sería:
          <br /> &nbsp;
          <Highlight
            isSpecial={
              comparacion.getDate() === 31 && comparacion.getMonth() === 11
            }
            isDate={true}
          >
            {comparacionFormateada}
          </Highlight>
        </TextItem>

        <TextItem>
          El tiempo transcurrido hasta ahora equivale a un viaje a Europa de{" "}
          <strong>15 días al año</strong> durante{" "}
          <Highlight isSpecial={months % 50 === 0}>
            {floor(months * 2)} años
          </Highlight>
          , o <strong>un mes al año</strong> durante{" "}
          <Highlight isSpecial={months % 50 === 0}>
            {floor(months)} años
          </Highlight>
          .
        </TextItem>

        <TextItem>
          También equivale a{" "}
          <Highlight isSpecial={vecesNeuquenMasNecochea % 1 === 0}>
            {vecesNeuquenMasNecochea} veces{" "}
          </Highlight>
          <br />
          la duración de la época <br />
          Neuquén + Necochea.
        </TextItem>

        <TextItem>
          Estuve viviendo en Irlanda&nbsp;
          <br />
          <Highlight
            isSpecial={
              unoDeCadaXDias * porcentaje >= 100 &&
              unoDeCadaXDias * (porcentaje - 0.01) < 100
            }
          >
            1 de cada {unoDeCadaXDias}
          </Highlight>{" "}
          días de mi vida.
        </TextItem>
      </div>
    </div>
  );
};

//Dias de diferencia con el periodo afuera de buenos aires en los anos '90

export default App;
