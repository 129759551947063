import React from "react";
import { circlesConfig } from "../../config";
import "./Circle.scss";

const Circle = ({ number, unit, text, smallText, percentage = 0.01 }) => {
  const esNumeroRedondo = percentage === 0;
  const mainClassName =
    "circle" + (esNumeroRedondo ? " circle--highlight" : "");
  const bottomTextClassName = "circle__bottom-text";

  /* const ref = useRef(null);
const [scale, setScale] = useState("1");
const [scaleContent, setScaleContent] = useState("1");

useEffect(() => {
  const selfPosition = {
    x: (ref.current?.offsetLeft || 0) + (ref.current?.offsetWidth || 0) / 2,
    y: (ref.current?.offsetTop || 0) + (ref.current?.offsetHeight || 0) / 2,
  };
  const distance = {
    x: Math.abs(mousePosition.x - selfPosition.x),
    y: Math.abs(mousePosition.y - selfPosition.y),
  };

  const resultante = Math.sqrt(
    Math.pow(distance.x, 2) + Math.pow(distance.y, 2)
  );

  const vw = window.innerWidth / 100;
  const margin = 11 * vw;
  const proportion = margin / (resultante + margin);
  //const smoothedProportion = Math.abs((Math.cos(Math.PI * proportion) - 1) / 2);
  const smoothedProportion =
    proportion < 0.5
      ? 8 * Math.pow(proportion, 4)
      : 1 - Math.pow(-2 * proportion + 2, 4) / 2;

  setScale(round(1 + 0.2 * smoothedProportion, 3).toString());
  //setScaleContent(round(1 + 0.1 * smoothedProportion, 3).toString()); <=== for giving perspective to h4
}, [mousePosition.x, mousePosition.y]);*/

  return (
    <div className={mainClassName}>
      <svg viewBox={circlesConfig.viewBox} className="circle__background">
        <path className="circle__background-path" d={circlesConfig.drawing} />
      </svg>
      <svg viewBox={circlesConfig.viewBox} className="circle__percentage">
        <path
          className={`circle__percentage-path  ${
            esNumeroRedondo ? "circle__percentage--escondido" : ""
          }`}
          strokeDasharray={`${percentage}, 100`}
          d={circlesConfig.drawing}
        />
      </svg>
      <h4 className="circle__main-text">
        <span className="circle__number">
          {number}
          {!!unit && <span className="circle__unit">{unit}</span>}
        </span>
      </h4>
      <p
        className={`${bottomTextClassName} ${
          smallText ? bottomTextClassName + "--small" : ""
        }`}
      >
        {text}
      </p>
    </div>
  );
};

export default Circle;
