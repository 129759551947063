export const nacimiento = new Date("1983-04-01T12:20:00.000Z");

export const arrivalDay = new Date("2018-05-04T14:00:00.000Z");

export const inDays = {
  averageYear: 365.25,
  averageMonth: 30.4375,
  cork2017: 14,
  neuquenAndNecochea: 1047,
};

export const featuredYears = [nacimiento.getFullYear(), 2000, 2018, 2023, 2024];

export const circlesConfig = {
  viewBox: "0 0 36 36",
  drawing:
    "M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831",
};

export const cicloDias = 1000;

export const circlesLabels = {
  days: {
    text: "días",
  },
  months: {
    text: "meses",
  },
  years: {
    text: "años",
  },
  vecesEnCork: {
    text: "veces la cantidad de días que estuve en Cork en 2017",
    smallText: true,
  },
  porcentaje: {
    unit: "%",
    text: "de mi vida",
  },
};
