import React from "react";
import "./TextItem.scss";

const TextItem = ({ children }) => {
  return (
    <div className="item">
      <p>{children}</p>
    </div>
  );
};

export default TextItem;
